<template>
  <v-container fluid class="container pa-5 mt-3">
    <v-row>
      <!-- <v-col
        v-for="({ ...attrs }, i) in stats"
        :key="i"
        cols="12"
        md="6"
        lg="3"
      >
        <v-skeleton-loader
          :loading="skeletonLoader"
          translate="trans"
          type="image"
        >
          <material-stats-card v-bind="attrs"> </material-stats-card>
        </v-skeleton-loader>
      </v-col> -->
      <v-col cols="12" md="12">
        <v-skeleton-loader
          :loading="skeletonLoader"
          translate="trans"
          type="table"
        >
          <material-card color="blue" full-header class="mt-2">
            <template #heading>
              <div class="white--text">
                <div class="font-weight-light">
                  {{ $t("requests.requestsChart") }}
                </div>
                <div class="text-caption"></div>
              </div>
            </template>
            <v-card-text>
              <line-chart
                v-if="loaded"
                :chartdata="chartdata"
                :options="options"
              />
            </v-card-text>
          </material-card>
        </v-skeleton-loader>

        <!--  <v-skeleton-loader
          :loading="skeletonLoader"
          translate="trans"
          type="table"
        >
          <material-card color="white" full-header class="mt-10">
            <template #heading>
              <div class="black--text">
                <div class="font-weight-light">
                  {{ $t("requests.requests") }}
                </div>
                <div class="text-caption"></div>
              </div>
            </template>
            <v-card-text>
              <v-data-table
                :headers="statisticsHeaders"
                :items="statistics"
                :items-per-page="10"
                :loading="loading"
                :loading-text="$t('loading')"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <div class="text-end">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          class="mx-1"
                          @click="showReport(item)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon> mdi-eye </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("Preview") }}</span>
                    </v-tooltip>
                  </div>
                </template>
              </v-data-table>
            </v-card-text>
          </material-card>
        </v-skeleton-loader> -->
      </v-col>
      <v-col cols="12" md="6">
        <v-skeleton-loader
          :loading="skeletonLoader"
          translate="trans"
          type="table"
        >
          <material-card color="pink darken-3" full-header class="mt-5">
            <template #heading>
              <div class="white--text">
                <div class="font-weight-light">
                  {{ $t("requests.requests") }}
                </div>
                <div class="text-caption"></div>
              </div>
            </template>

            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="requests"
                :items-per-page="10"
                :loading="loading"
                :loading-text="$t('loading')"
              >
                <template v-slot:[`item.status`]="{ item }">
                  <v-chip :color="getColor(item.status)" dark>
                    {{ requestStatus[item.status] }}
                  </v-chip>
                </template>

                <template v-slot:[`item.createdDate`]="{ item }">
                  {{ item.createdDate | moment("YYYY-MM-DD - hh:mm A") }}
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                  <div class="text-end">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          class="mx-1"
                          @click="showReport(item)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon> mdi-eye </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("Preview") }}</span>
                    </v-tooltip>
                  </div>
                </template>
              </v-data-table>
            </v-card-text>
          </material-card>
        </v-skeleton-loader>
      </v-col>
      <v-col cols="12" md="6">
        <v-skeleton-loader
          :loading="skeletonLoader"
          translate="trans"
          type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions"
        >
          <material-card class="mt-5" color="orange darken-2" full-header>
            <template #heading>
              <div class="white--text">
                <div class="font-weight-light">
                  {{ $t("requests.addRequest") }}
                </div>
                <div class="text-caption">
                  {{ $t("requests.addRequestData") }}
                </div>
              </div>
            </template>

            <v-card class="overflow-y-auto" :max-height="580" elevation="0">
              <v-card-text>
                <v-row>
                  <v-col cols="12" lg="6" v-for="(node, i) in nodes" :key="i">
                    <v-card elevation="1" outlined>
                      <v-list-item three-line>
                        <v-list-item-content>
                          <div class="text-overline"></div>
                          <v-list-item-title class="mb-1">
                            <v-icon small color="primary">mdi-domain</v-icon>
                            {{ node.caption }}
                          </v-list-item-title>

                          <v-list-item-subtitle>
                            <v-icon small color="primary"
                              >mdi-desktop-mac</v-icon
                            >
                            {{ node.product.productName }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            <v-icon small color="primary">mdi-text</v-icon>
                            {{ node.product.description }}
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar
                          size="80"
                          rounded
                          color="grey lighten-1"
                        >
                          <v-img
                            :lazy-src="require('@/assets/enterprise.png')"
                            aspect-ratio="1"
                            :src="require('@/assets/enterprise.png')"
                          ></v-img>
                        </v-list-item-avatar>
                      </v-list-item>

                      <v-card-actions>
                        <v-btn
                          color="primary"
                          outlined
                          rounded
                          text
                          min-width="80"
                          @click="bookingDialog(node)"
                        >
                          {{ $t("requests.addRequest") }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </material-card>
        </v-skeleton-loader>
      </v-col>
    </v-row>
    <v-dialog v-model="addAppointmentDialog" persistent max-width="600px">
      <v-form
        ref="addAppointmentForm"
        v-model="addAppointmentValid"
        lazy-validation
      >
        <v-card>
          <v-toolbar color="primary darken-1" dark>
            <v-card-title>
              <span class="">{{
                $t("add") + " " + $t("requests.requests")
              }}</span>
            </v-card-title>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row no-gutters>
                <v-col cols="12" md="12">
                  <span>{{ $t("nodes.nodeName") }}:</span>
                  <v-chip class="ma-3" label>
                    <v-icon>mdi-domain</v-icon>
                    {{ caption }}
                  </v-chip>
                </v-col>
                <v-col cols="12" md="12">
                  <v-select
                    :items="reports"
                    item-text="reportCaption"
                    item-value="reportGuid"
                    :rules="rules"
                    v-model="editedItem.reportGuid"
                    :label="$t('requests.reportCaption')"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="6" class="px-2">
                  <v-dialog
                    ref="dialog"
                    v-model="modal1"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="parameterData.fromDate"
                        :rules="rules"
                        :label="$t('fromDate')"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="parameterData.fromDate" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal1 = false">
                        Cancel
                      </v-btn>
                      <v-btn text color="primary" @click="modal1 = false">
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <!-- :return-value.sync="date" -->
                <v-col cols="12" md="6" class="px-2">
                  <v-dialog
                    ref="dialog"
                    v-model="modal2"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="parameterData.toDate"
                        :rules="rules"
                        :label="$t('toDate')"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="parameterData.toDate" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal2 = false">
                        Cancel
                      </v-btn>
                      <v-btn text color="primary" @click="modal2 = false">
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>

                <v-spacer></v-spacer>

                <v-col cols="12" md="12" class="px-2">
                  <v-text-field
                    persistent-hint
                    v-model="editedItem.description"
                    :label="$t('requests.description')"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-btn
              color="green darken-1"
              class="white--text"
              @click="saveRequest"
              :disabled="!addAppointmentValid"
              :min-width="100"
            >
              <v-icon>mdi-content-save-outline</v-icon>
              {{ $t("save") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="closeAppointmentDialog">
              {{ $t("cancel") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>
<script>
//import MaterialStatsCard from "../components/base/MaterialStatsCard.vue";
import MaterialCard from "../components/base/MaterialCard.vue";
import axios from "axios";
import i18n from "../i18n";
import moment from "moment";
import LineChart from "../components/Chart.vue";

export default {
  components: {
    MaterialCard,
    LineChart,
  },
  data: () => ({
    options: {
      responsive: true,
      maintainAspectRatio: false,
      beginAtZero: true,
      scales: {
        xAxes: [
          {
            ticks: {
              fontSize: 17,
            },
          },
        ],
      },
    },
    loaded: false,
    chartdata: {
      labels: [],
      datasets: [],
    },
    stats: [
      {
        subIcon: "mdi-magnify-plus-outline",
        subText: i18n.t("showMore"),
        subTextLink: "/patientschedule",
        color: "#FD9A13",
        icon: "mdi-calendar-multiple-check",
        title: i18n.t("appointments.appointments"),
        value: "0",
      },
      {
        subIcon: "mdi-magnify-plus-outline",
        subText: i18n.t("showMore"),
        subTextLink: "/patients",
        color: "primary",
        icon: "mdi-account-plus",
        title: i18n.t("patients.registaration"),
        value: "0",
      },
      {
        subIcon: "mdi-magnify-plus-outline",
        subText: i18n.t("showMore"),
        subTextLink: "/bills",
        color: "success",
        icon: "mdi-receipt",
        title: i18n.t("bills.bills"),
        value: "0",
      },
      {
        subIcon: "mdi-chart-bubble",
        subText: i18n.t("procedures.procedures"),

        color: "info",
        icon: "mdi-doctor",
        title: i18n.t("services.services"),
        value: "0",
      },
    ],
    headers: [
      {
        text: i18n.t("requests.reportCaption"),
        value: "report.reportCaption",
      },
      { text: i18n.t("nodes.nodes"), value: "node.caption" },
      { text: i18n.t("date"), value: "createdDate" },
      { text: i18n.t("requests.status"), value: "status" },
      { text: "", value: "actions" },
    ],
    tabs: 0,
    loading: false,
    appointments: {
      0: [],
      1: [],
      2: [],
      3: [],
    },
    rules: [(value) => !!value || i18n.t("ThisFieldIsRequired")],
    nodes: [],
    patient: {},
    statistics: [],
    statisticsHeaders: [],
    reports: [],
    requests: [],
    parameterData: {
      fromDate: moment(new Date()).format("yyyy-MM-DD"),
      toDate: moment(new Date()).format("yyyy-MM-DD"),
    },
    caption: "",
    modal1: false,
    modal2: false,
    appointmentData: {},
    addAppointmentDialog: false,
    addPatientDialog: false,
    addAppointmentValid: false,
    addPatientValid: false,
    requestStatus: {
      0: i18n.t("requestStatus.0"),
      1: i18n.t("requestStatus.1"),
      2: i18n.t("requestStatus.2"),
    },
    doctorName: "",
    patientName: "",
    gender: [
      { text: i18n.t("patients.male"), value: 0 },
      { text: i18n.t("patients.female"), value: 1 },
    ],
    countryGuid: "",
    cityGuid: "",
    countries: [],
    filterCities: [],
    menu: null,
    activePicker: null,
    skeletonLoader: false,
    editedItem: {},
    defaultItem: {
      requestGuid: "00000000-0000-0000-0000-000000000000",
      parameterData: {
        fromDate: moment(new Date()).format("yyyy-MM-DD"),
        toDate: moment(new Date()).format("yyyy-MM-DD"),
      },
    },
  }),
  created() {
    // this.getDashboard();
    // this.getAppointment();
    this.getNodes();
    //this.getReports();
    this.refreshTable();
    this.getStatistics();
  },
  watch: {
    countryGuid(val) {
      this.filterCities = val.cities;
    },
  },
  computed: {
    computedDateFormatted() {
      return this.$moment(this.patient.patientDob).format("YYYY-MM-DD");
    },
  },
  mounted() {
    // window.addEventListener("focus", this.pageFocused);
    setInterval(() => {
      this.refreshTable();
    }, 30000);
  },
  methods: {
    formatDate(date) {
      if (date != null && date != "") {
        var myDate = new Date(date);
        var d = myDate.getDate();
        var m = myDate.getMonth() + 1; //Month from 0 to 11
        var y = myDate.getFullYear();
        return (
          "" + y + "/" + (m <= 9 ? "0" + m : m) + "/" + (d <= 9 ? "0" + d : d)
        );
      }
      return "";
    },
    getDashboard() {
      this.skeletonLoader = true;
      axios
        .get("Dashboard/Get")
        .then((response) => {
          this.stats[0].value =
            response.data.data.appointments.toLocaleString();
          this.stats[1].value = response.data.data.patients.toLocaleString();
          this.stats[2].value = response.data.data.bills.toLocaleString();
          this.stats[3].value = response.data.data.procedures.toLocaleString();
          this.skeletonLoader = false;
        })
        .finally(() => {});
    },
    getAppointment() {
      this.loading = true;
      axios
        .get("Appointment/Get")
        .then((response) => {
          if (response.data.data.length > 0) {
            this.appointments[0] = response.data.data.filter(
              (m) => m.appointmentStatus === 0
            );
            this.appointments[1] = response.data.data.filter(
              (m) => m.appointmentStatus === 1
            );
            this.appointments[2] = response.data.data.filter(
              (m) => m.appointmentStatus === 2
            );
            this.appointments[3] = response.data.data.filter(
              (m) => m.appointmentStatus === 3
            );
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getNodes() {
      axios.get("Nodes/Get").then((response) => {
        this.nodes = response.data.data;
      });
    },
    getStatistics() {
      axios.get("Nodes/GetStatistics").then((response) => {
        this.statisticsHeaders = response.data.data.jsonHeader;
        this.statistics = response.data.data.jsonData;

        var colors = [
          "rgb(255, 99, 132)",
          "rgb(75, 192, 192)",
          "rgb(255, 159, 64)",
          "rgb(201, 203, 207)",
          "rgb(255, 205, 86)",
          "rgb(54, 162, 235)",
          "rgb(153, 102, 255)",
        ];

        //set the labels of the chart
        if (
          this.statisticsHeaders &&
          this.statisticsHeaders.length > 0 &&
          this.statistics &&
          this.statistics.length > 0
        ) {
          for (let i = 0; i < this.statisticsHeaders.length; i++) {
            if (
              this.statisticsHeaders[i].value == "Caption" ||
              this.statisticsHeaders[i].value == "company" // added by zaid to fix the issue of the chart not showing the caption correctly
            )
              continue;
            this.chartdata.labels.push(this.statisticsHeaders[i].text);
          }

          //set the data set of chart
          for (let i = 0; i < this.statistics.length; i++) {
            var dataSet = {
              label: this.statistics[i].Caption,
              backgroundColor: colors[i % 7],
              data: Object.values(this.statistics[i]),
            };
            this.chartdata.datasets.push(dataSet);
          }
        }

        this.loaded = true;
      });
    },
    getReports(nodeGuid) {
      var reqlink = "Reports/Get?nodeGuid=" + nodeGuid;
      axios
        .get(reqlink)
        .then((response) => {
          this.reports = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    saveRequest() {
      var val = this.$refs.addAppointmentForm.validate();
      if (val) {
        if (this.editedIndex > -1) {
          var updatedItem = this.requests[this.editedIndex];
          updatedItem.requestGuid = this.editedItem.requestGuid;
          // updatedItem.parameterData = JSON.stringify(this.parameterData);
          updatedItem.description = this.editedItem.description;
          updatedItem.reportGuid = this.editedItem.reportGuid;
          updatedItem.nodeGuid = this.editedItem.nodeGuid;
          axios
            .post("requests/Save", updatedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.close();
                //this.refreshTable();
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              console.log(e);
            });
          Object.assign(this.requests[this.editedIndex], this.editedItem);
        } else {
          this.editedItem.parameterData = JSON.stringify(this.parameterData);
          axios
            .post("requests/Save", this.editedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.closeAppointmentDialog();
                this.refreshTable();
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              this.refreshTable();
              console.log(e);
            });
        }
      }
    },
    refreshTable() {
      axios
        .get("requests/Get")
        .then((response) => {
          this.requests = response.data.data;
          this.parentrequest = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getPatients() {
      axios
        .get("Patient")
        .then((response) => {
          this.patients = response.data.data;
        })
        .finally(() => {});
    },
    getCountries() {
      axios.get("Country").then((response) => {
        this.countries = response.data.data;
        axios.get("Setting/GetCountry").then((resp) => {
          this.countryGuid = resp.data.data.countryGuid;
          this.cityGuid = resp.data.data.cityGuid;
          setTimeout(() => {
            this.filterCities = this.countries.filter((o) => {
              return o.guid == this.countryGuid;
            })[0].cities;
          }, 500);
        });
      });
    },
    bookingDialog(node) {
      //this.appointmentData = {};
      //this.appointmentData.appointmentDate = new Date();
      this.editedItem.nodeGuid = node.nodeGuid;
      this.caption = node.caption;
      this.getReports(this.editedItem.nodeGuid);
      this.addAppointmentDialog = true;
    },
    closeAppointmentDialog() {
      this.editedItem = {};
      this.parameterData = {};
      this.selec;
      this.addAppointmentDialog = false;
    },
    getColor(state) {
      if (state == 2) return "green";
      if (state == 1) return "primary";
      return "orange";
    },
    showReport(item) {
      this.editedIndex = this.requests.indexOf(item);
      this.editedItem = Object.assign({}, item);
      if (this.editedItem.status == 2) {
        this.$router.push({
          name: "requests.show",
          params: { requestGuid: this.editedItem.requestGuid },
          // props: { vType: this.editedItem.billType },
        });
      } else {
        this.$toast.error(this.$t("error.ReportNotReady"));
      }
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    closePatientDialog() {
      this.addPatientDialog = false;
      this.patient = {};
    },
    patientDialog() {
      this.patient = {};
      this.patient = {
        patientGender: 0,
        patientDob: this.$moment(new Date("1990-01-01")).format("YYYY-MM-DD"),
        cityGuid: this.cityGuid,
      };
      this.addPatientDialog = true;
    },
    // pageFocused() {
    //   if (this.addAppointmentDialog) {
    //     this.getPatients();
    //   }
    // },
  },
};
</script>
<style>
.v-application .text-caption {
  font-family: inherit !important;
}
</style>
