<script>
import { Bar } from "vue-chartjs";

// eslint-disable-next-line no-undef
Chart.plugins.register({
  afterDatasetsDraw: function (chart) {
    // To only draw at the end of animation, check for easing === 1
    var ctx = chart.ctx;
    chart.data.datasets.forEach(function (dataset, i) {
      var meta = chart.getDatasetMeta(i);
      if (!meta.hidden) {
        meta.data.forEach(function (element, index) {
          // Draw the text in black, with the specified font
          ctx.fillStyle = "rgb(0, 0, 0)";
          var fontSize = 14;
          var fontStyle = "normal";
          var fontFamily = "Helvetica Neue";
          // eslint-disable-next-line no-undef
          ctx.font = Chart.helpers.fontString(fontSize, fontStyle, fontFamily);
          // Just naively convert to string for now

          var dataString = Number.isInteger(dataset.data[index])
            ? dataset.data[index].toLocaleString(undefined, {
                minimumFractionDigits: 0,
              })
            : dataset.data[index].toString();
          // Make sure alignment settings are correct
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          var padding = 5;
          var position = element.tooltipPosition();
          ctx.fillText(
            dataString,
            position.x,
            position.y - fontSize / 2 - padding
          );
        });
      }
    });
  },
});

export default {
  extends: Bar,
  props: {
    chartdata: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    this.renderChart(this.chartdata, this.options);
  },
};
</script>
